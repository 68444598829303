import $ from 'jquery'

class SimpleroRevenueTicker {
  constructor(elm) {
    this.update = this.update.bind(this)
    this.elm = $(elm)
    this.startTime = new Date()
    this.amountElm = this.elm.find(
      '.simplero-new-revenue-ticker__revenue-amount'
    )
    this.dollarsPerMS =
      this.elm.data('revenue-cents-per-hour') / (60 * 60 * 1000 * 100)
    setInterval(this.update, 166)
  }

  update() {
    const elapsedMS = new Date() - this.startTime
    let sales = this.dollarsPerMS * elapsedMS
    sales = sales.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return this.amountElm.text(sales)
  }
}

$(function () {
  return $('.simplero-new-revenue-ticker__container').each(function () {
    return new SimpleroRevenueTicker(this)
  })
})
